/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.userinterface-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 5%;
    border-style: solid none none solid;
    border-color: royalblue;
    border-radius: 5px;
}

.jus-icon {
    float: right;
}

.adminpasswordfield {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 5%;
    border-style: solid none none solid;
    border-color: royalblue;
    border-radius: 5px;
}

.filterArea {
    margin: 2%;
}

#tillad-sletning-button {
    margin-right: 5%;
}

.adminfield {
    margin-bottom: 2%;
}

.registrationfield {
    margin-top: 2%;
}
